"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.selectedTheme = exports.default = exports.changeTheme = void 0;

var _toolkit = require("@reduxjs/toolkit");

var initialState = {
  theme: 'light'
};
var themeSlice = (0, _toolkit.createSlice)({
  name: 'theme',
  initialState: initialState,
  reducers: {
    changeTheme: function changeTheme(state, _ref) {
      var payload = _ref.payload;
      state.theme = payload;
    }
  }
});

var selectedTheme = function selectedTheme(state) {
  return state.theme.theme;
};

exports.selectedTheme = selectedTheme;
var changeTheme = themeSlice.actions.changeTheme;
exports.changeTheme = changeTheme;
var _default = themeSlice.reducer;
exports.default = _default;