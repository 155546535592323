import React, { useEffect, useState } from 'react';
import {
  Routes,
  Route,
} from "react-router-dom";

import { Home } from './features/home/home';
import { Market } from './features/market';
import './i18n';

import { lightTheme, darkTheme } from './vaishno/dist/themes';
import { selectedTheme } from './vaishno/dist/slices';

import { ThemeProvider, useTheme } from '@mui/material/styles';


import { useSelector } from 'react-redux';


function App() {

  const themeName = useSelector(selectedTheme);
  const theme = useTheme()
  useEffect(() => {
    console.log('OK')
    document.body.backgroundColor = theme.palette.background.paper;
  }, [])  
  return (
    <ThemeProvider theme={themeName === 'light' ? lightTheme : darkTheme}>
      <Routes>
        <Route path="/" element={<Market />} />
        <Route path="/:market/:variety" element={<Home />} />
      </Routes>
    </ThemeProvider>
  );
}

export default App;
