"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.usePrices = void 0;

var _react = _interopRequireDefault(require("react"));

var _dexieReactHooks = require("dexie-react-hooks");

var _database = require("../database");

var usePrices = function usePrices(market, variety) {
  var start = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : new Date(new Date().setDate(new Date().getDate() - 60)).toISOString().split('T').at(0);
  var end = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : new Date().toISOString().split('T').at(0);
  var prices = (0, _dexieReactHooks.useLiveQuery)(function () {
    return _database.database.prices.where('date').between(start, end).and(function (elem) {
      return elem.market === market;
    }).and(function (elem) {
      return elem.variety === variety;
    }).toArray();
  }, [market, variety, start, end]);
  return prices;
};

exports.usePrices = usePrices;