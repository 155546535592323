"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.database = void 0;

var _dexie = _interopRequireDefault(require("dexie"));

var database = new _dexie.default(process.env.REACT_APP_DATABASE_NAME); // TODO: Make more version of database

exports.database = database;
database.version(1).stores({
  prices: '[date+market+variety], market, variety, minimum, maximum, modal, arrivals, changeMinimum, changeMaximum, changeModal, changeArrivals, changeMinimumPercentage, changeMaximumPercentage, changeModalPercentage, changeArrivalsPercentage',
  markets: 'id, id, name',
  varieties: '[id+market], id, market, name',
  bookmarks: '[variety+market], variety, market'
});