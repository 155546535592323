"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _DefaultTheme = require("./DefaultTheme");

Object.keys(_DefaultTheme).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _DefaultTheme[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _DefaultTheme[key];
    }
  });
});

var _LightTheme = require("./LightTheme");

Object.keys(_LightTheme).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _LightTheme[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _LightTheme[key];
    }
  });
});

var _DarkTheme = require("./DarkTheme");

Object.keys(_DarkTheme).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _DarkTheme[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _DarkTheme[key];
    }
  });
});