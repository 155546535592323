"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.lightTheme = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/esm/defineProperty"));

var _objectSpread2 = _interopRequireDefault(require("@babel/runtime/helpers/esm/objectSpread2"));

var _styles = require("@mui/material/styles");

var _defaultTheme = _interopRequireDefault(require("@mui/material/styles/defaultTheme"));

var lightTheme = (0, _styles.createTheme)({
  name: 'light',
  palette: (0, _objectSpread2.default)({
    mode: 'light'
  }, _defaultTheme.default.palette),
  transitions: (0, _objectSpread2.default)({}, _defaultTheme.default.transitions),
  typography: {
    button: {
      textTransform: 'none'
    }
  },
  shape: {
    borderRadius: 0
  },
  components: {
    MuiList: {
      defaultProps: {
        disablePadding: true
      }
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          display: 'list-item',
          paddingLeft: '8px',
          paddingRight: '8px'
        }
      }
    },
    MuiListItemButton: {
      defaultProps: {
        alignItems: 'center',
        divider: false,
        disableGutters: true
      },
      styleOverrides: {
        root: {// '&:hover': {
          //   background: 'none'
          // }
        }
      }
    },
    MuiListItemText: {
      styleOverrides: {
        root: (0, _defineProperty2.default)({
          textAlign: 'left',
          marginTop: 1,
          marginBottom: 1
        }, _defaultTheme.default.breakpoints.not('xs'), {
          textAlign: 'center'
        })
      }
    },
    MuiListItemIcon: {
      defaultProps: {}
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          fontWeight: 'bold',
          color: _defaultTheme.default.palette.text.primary
        },
        root: (0, _defineProperty2.default)({}, _defaultTheme.default.breakpoints.only('xs'), {
          fontSize: '12px'
        })
      }
    },
    MuiTypography: {
      styleOverrides: {
        body1: (0, _defineProperty2.default)({}, _defaultTheme.default.breakpoints.only('xs'), {
          fontSize: '12px'
        })
      }
    },
    MuiListSubheader: {
      styleOverrides: {
        root: {
          display: 'list-item',
          paddingLeft: '8px',
          paddingRight: '8px',
          paddingTop: '4px',
          paddingBottom: '4px'
        }
      }
    },
    MuiToolbar: {
      styleOverrides: {}
    }
  }
});
exports.lightTheme = lightTheme;