"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useBookmarks = void 0;

var _react = _interopRequireDefault(require("react"));

var _dexieReactHooks = require("dexie-react-hooks");

var _database = require("../database");

var useBookmarks = function useBookmarks(market, variety) {
  return (0, _dexieReactHooks.useLiveQuery)(function () {
    return _database.database.bookmarks.where('market').equals(market || '').and(function (elem) {
      return elem.variety === variety;
    }).first()[(market, variety)];
  });
};

exports.useBookmarks = useBookmarks;