import { configureStore, combineReducers } from '@reduxjs/toolkit';

import bookmarkSlice from '../vaishno/dist/slices/bookmark';
import themeSlice from '../vaishno/dist/slices/theme';
import filterSlice from '../vaishno/dist/slices/filter';
import selectionSlice from '../vaishno/dist/slices/selection';

const reducers = combineReducers({
  selection: selectionSlice,
  bookmarks: bookmarkSlice,
  theme: themeSlice,
  filter: filterSlice  
});

const store = configureStore({
  reducer: reducers,
  devTools: true,  
});


export { store }
