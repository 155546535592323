import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';

import {
  Selection,
  Navigation
} from '../../vaishno/dist';

import { Heading } from '../heading/heading';

import LinearProgress from '@mui/material/LinearProgress';

import { useNavigate } from 'react-router-dom';

import {
  useMarkets,
  useVarieties
} from '../../vaishno/dist/hooks';

import {
  selectedMarket,
  selectedVariety,
  changeMarket,
  changeVariety
} from '../../vaishno/dist/slices';


import {
  useSelector,
  useDispatch
} from 'react-redux';

export const Market = () => {

  const navigate = useNavigate()

  const { t } = useTranslation();

  const theme = useTheme()
  
  const dispatch = useDispatch()
  const market = useSelector(selectedMarket);
  const variety = useSelector(selectedVariety)
  const markets = useMarkets()
  const varieties = useVarieties(market)
  if(!markets || markets.length === 0) {
    return (
      <>
        <LinearProgress />
      </>)
  }  
  return (
    <>
      <Navigation title="DONE" type="done" onClick={() => navigate(`/${market}/${variety}`)}/>
      <Heading />
      <Grid container rowSpacing={1} className="selection-grid" sx={{mt: '64px'}}>    
        <Grid xs={12} md={12}>
          <Typography sx={{textAlign: 'center', fontWeight: 'bold', color: theme.palette.primary.main}}> {t('Select Market')} </Typography>
          <Selection
            data={markets}
            selected={market}
            onChange={(v) => dispatch(changeMarket(v))}
          />
        </Grid>
        <Grid xs={12} md={12}>
          <Typography sx={{textAlign: 'center', fontWeight: 'bold', color: theme.palette.primary.main}}> {t('Select Variety')} </Typography>
          <Selection
            data={varieties || []}
            selected={variety}
            onChange={(v) => dispatch(changeVariety(v))}
          />
        </Grid>              
      </Grid>
    </>
  )
}
