"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.darkTheme = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/esm/defineProperty"));

var _objectSpread2 = _interopRequireDefault(require("@babel/runtime/helpers/esm/objectSpread2"));

var _styles = require("@mui/material/styles");

var _defaultTheme = _interopRequireDefault(require("@mui/material/styles/defaultTheme"));

var defaultTheme = (0, _styles.createTheme)({
  palette: {
    mode: 'dark'
  }
});
var darkTheme = (0, _styles.createTheme)({
  name: 'dark',
  palette: {
    mode: 'dark'
  },
  transitions: (0, _objectSpread2.default)({}, _defaultTheme.default.transitions),
  typography: {
    fontFamily: 'Nunito Sans, sans-serif',
    button: {
      textTransform: 'none'
    }
  },
  shape: {
    borderRadius: 0
  },
  components: {
    MuiList: {
      defaultProps: {
        disablePadding: true
      }
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          display: 'list-item',
          paddingLeft: '8px',
          paddingRight: '8px',
          paddingTop: '4px',
          paddingBottom: '4px'
        }
      }
    },
    MuiListItemButton: {
      defaultProps: {
        alignItems: 'center',
        divider: false,
        disableGutters: true
      },
      styleOverrides: {}
    },
    MuiListItemText: {
      styleOverrides: {
        root: (0, _defineProperty2.default)({
          textAlign: 'left',
          marginTop: 1,
          marginBottom: 1
        }, defaultTheme.breakpoints.not('xs'), {
          textAlign: 'center'
        })
      }
    },
    MuiListItemIcon: {
      defaultProps: {}
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          fontWeight: 'bold',
          color: defaultTheme.palette.text.primary
        },
        root: (0, _defineProperty2.default)({}, defaultTheme.breakpoints.only('xs'), {
          fontSize: '12px'
        })
      }
    },
    MuiTypography: {
      styleOverrides: {
        body1: (0, _defineProperty2.default)({}, defaultTheme.breakpoints.only('xs'), {
          fontSize: '12px'
        })
      }
    },
    MuiListSubheader: {
      styleOverrides: {
        root: {
          display: 'list-item',
          paddingLeft: '8px',
          paddingRight: '8px',
          paddingTop: '4px',
          paddingBottom: '4px'
        }
      }
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          paddingLeft: '8px !important',
          paddingRight: '8px !important'
        }
      }
    }
  }
});
exports.darkTheme = darkTheme;