import React,  { useState, useEffect, useRef } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

import {
  Filter,
  LineChart,
  List,
} from '../../vaishno/dist';

import {
  usePrices,
  useMarkets,
  useVarieties
} from '../../vaishno/dist/hooks';

import {
  selectedMarket,
  selectedVariety,
  selectedFilter,
  changeFilter,
  changeMarket,
  changeVariety,
  selectedDates
} from '../../vaishno/dist/slices';


import {
  useSelector,
  useDispatch
} from 'react-redux';


import LinearProgress from '@mui/material/LinearProgress';

import { Heading } from '../heading/heading';

import { useTranslation } from 'react-i18next';

import { Navigation } from '../../vaishno/dist/components/Navigation/Navigation';

import { useNavigate, useParams } from 'react-router-dom';

export const Home = () => {

  const headerRef = useRef(null)
  const params = useParams();
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(changeMarket(params.market))
    dispatch(changeVariety(params.variety))
    console.log(headerRef.current)
  }, []);

  const dates = useSelector(selectedDates);

  const { t } = useTranslation();

  const market = useSelector(selectedMarket);
  const markets = useMarkets();
  const varieties = useVarieties(market)
  const variety = useSelector(selectedVariety);  
  const prices = usePrices(market, variety, dates.start, dates.end)
  const filter = useSelector(selectedFilter);

  if(!prices) {
    return (
      <>
        <LinearProgress />
      </>)
  }
  
  else if (prices.length === 0) {    
    navigate('/')
  }

  const applyFilter = (v) => {
    dispatch(changeFilter(v))
  }

  const onNext = () => {
    if (varieties.length <= 1) {
      const currentMarketIdx = markets.findIndex((item) => item.id === market)
      const nextMarket = markets.at(currentMarketIdx + 1) || markets.at(0)
      console.log(nextMarket)
      window.location = `/${nextMarket.id}/${variety}`
      // navigate(`/${nextMarket.id}/${variety}`)
    }
    // const current = varieties.indexOf(variety)
    // const next = varieties.at(current + 1) || varieties[0]    
    // navigate(`/${market}/${next}`)
  }

  const onPrev = () => {
    console.log('OK')
    if (varieties.length <= 1) {
      const currentMarketIdx = markets.findIndex((item) => item.id === market)
      const nextMarket = markets.at(currentMarketIdx - 1) || markets.at(-1)
      console.log(nextMarket)
      window.location = `/${nextMarket.id}/${variety}`
      // navigate(`/${nextMarket.id}/${variety}`)
    }    
    // const current = varieties.indexOf(variety)
    // const next = varieties.at(current - 1) || varieties.at(-1)
    
    // navigate(`/${market}/${next}`)
  }
  
  return (
    <>
      <Navigation title="CHOOSE" type="choose" onClick={() => navigate('/')}/>
      <Heading />
      <Box sx={{position: 'fixed', top: '64px', bottom: 'auto', width: '100%', zIndex: 1200}}>
        <Filter
          title={`${t('intlDateTime', { val: new Date(prices.at(0).date)})} ${t('to')} ${t('intlDateTime', { val: new Date(prices.at(-1).date)})}`}
          view={filter}
          onChange={(v) => applyFilter(v)}
          onNext={onNext}
          onPrevious={onPrev}
        />
        <Paper sx={{
                 height: 400,
                 width: '100%'
               }}>
          <LineChart data={prices} />
        </Paper>        
      </Box>
      <Grid container sx={{mt: '100px'}}>
        {
          prices && 
              <Grid xs={12} md={12}>
                <List data={prices.slice().reverse()} />
              </Grid>
        }
      </Grid>
    </>
  )
}
