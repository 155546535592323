import React, { useEffect } from 'react'
import { useTheme } from '@mui/material/styles';

import Grid from '@mui/material/Unstable_Grid2';
import Paper from '@mui/material/Paper';

import {
  Header
} from '../../vaishno/dist';

import {
  selectedMarket,
  selectedVariety,
  selectedFilter,
  selectedBookmarks,
  bookmark,
  deleteBookmark,
  changeTheme,
  changeMarket,
  changeVariety
} from '../../vaishno/dist/slices';


import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import { useNavigate } from 'react-router-dom';

export const Heading = () => {

  const navigate = useNavigate()

  const theme = useTheme()

  const dispatch = useDispatch()
  const bookmarks = useSelector(selectedBookmarks)

  const market = useSelector(selectedMarket)
  const variety = useSelector(selectedVariety);
  const { i18n, t } = useTranslation()

  useEffect(() => {
    document.body.style.backgroundColor = theme.palette.background.paper;
  }, [theme])    
  
  const handleBookmark = () => {
    if (!bookmarks.find((item) => item.market === market && item.variety === variety)) {
      dispatch(bookmark({market, variety}))
      
    }
    else {
      const bm = bookmarks.find((item) => item.market === market && item.variety === variety)
      dispatch(deleteBookmark(bm.id))
    }
  }

  const gotoBookmark = (bm) => {
    dispatch(changeMarket(bm.market))
    dispatch(changeVariety(bm.variety))
    navigate(`/${bm.market}/${bm.variety}`)
  }

  return (
    <Header
      title={t('title')}
      onBookmarkEdit={handleBookmark}
      onBookmarkClick={(bm) => gotoBookmark(bm)}
      onThemeToggle={() => dispatch(changeTheme(theme.name === 'light' ? 'dark' : 'light'))}
      market={market}
      variety={variety}
      bookmarks={bookmarks || []}
      languages={t('supportedLocales', { returnObjects: true})} />    
  )
}
