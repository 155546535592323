"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.selectedVariety = exports.selectedMarket = exports.default = exports.changeVariety = exports.changeMarket = void 0;

var _toolkit = require("@reduxjs/toolkit");

var initialState = {
  market: '',
  variety: ''
};
var selectionSlice = (0, _toolkit.createSlice)({
  name: 'selection',
  initialState: initialState,
  reducers: {
    changeMarket: function changeMarket(state, _ref) {
      var payload = _ref.payload;
      state.market = payload;
    },
    changeVariety: function changeVariety(state, _ref2) {
      var payload = _ref2.payload;
      state.variety = payload;
    }
  }
});

var selectedMarket = function selectedMarket(state) {
  return state.selection.market;
};

exports.selectedMarket = selectedMarket;

var selectedVariety = function selectedVariety(state) {
  return state.selection.variety;
};

exports.selectedVariety = selectedVariety;
var _selectionSlice$actio = selectionSlice.actions,
    changeMarket = _selectionSlice$actio.changeMarket,
    changeVariety = _selectionSlice$actio.changeVariety;
exports.changeVariety = changeVariety;
exports.changeMarket = changeMarket;
var _default = selectionSlice.reducer;
exports.default = _default;